//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'input-clear',

	inheritAttrs: false,
	props:
	{
		value: {
			type: String,
		}
	},

	computed:
	{

	},

	// watch:
	// {
	// 	value(newVal)
	// 	{
	// 		console.log('input changed to: ', newVal);
	// 	}
	// },

	methods: {
		clear()
		{
			this.$emit('input', '');
		}
	}
}
