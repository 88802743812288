//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { quinticInOut } from '@mozillareality/easing-functions'
import lerp from 'lerp'

const line1 = {
	from: [13, 14.6, 25, 14.6],
	to: [13.6, 13.4, 24.3, 24.1]
}

const line2 = {
	from: [13, 19, 25, 19],
	to: [19, 19, 19, 19],
}

const line3 = {
	from: [13, 23.4, 25, 23.4],
	to: [13.6, 24.1, 24.3, 13.4,]
}

export default {
	data()
	{
		return {
			isOpen: false,
			isTransitioning: false,
			middleOpacity: 1,
			line1x1: line1.from[0],
			line1y1: line1.from[1],
			line1x2: line1.from[2],
			line1y2: line1.from[3],

			line2x1: line2.from[0],
			line2y1: line2.from[1],
			line2x2: line2.from[2],
			line2y2: line2.from[3],

			line3x1: line3.from[0],
			line3y1: line3.from[1],
			line3x2: line3.from[2],
			line3y2: line3.from[3],
		}
	},

	watch:
	{
		isOpen(newVal)
		{
			const self = this;
			let t = 0;
			let stepFunc = null;

			const DURATION_MS = 500;
			const easingFunc = quinticInOut;

			function onTick(dt)
			{
				self.isTransitioning = true;
				let willStop = false;
				t += (dt / DURATION_MS);
				willStop = (t >= 1.0);
				t = Math.min(t, 1.0);
				stepFunc( easingFunc(t) )
				// let pos = lerp( from, destVal, easingFunc(t) );
				// window.scrollTo(0, pos);

				if (willStop)
				{
					self.$raf.removeListener('tick', onTick);
					self.isTransitioning = false;
				}
			}

			if (newVal==true)
			{
				this.$emit('burger-open');
				stepFunc = (t) =>
				{
					this.line1x1 = lerp( line1.from[0], line1.to[0], t )
					this.line1y1 = lerp( line1.from[1], line1.to[1], t )
					this.line1x2 = lerp( line1.from[2], line1.to[2], t )
					this.line1y2 = lerp( line1.from[3], line1.to[3], t )

					this.line2x1 = lerp( line2.from[0], line2.to[0], t )
					this.line2y1 = lerp( line2.from[1], line2.to[1], t )
					this.line2x2 = lerp( line2.from[2], line2.to[2], t )
					this.line2y2 = lerp( line2.from[3], line2.to[3], t )

					this.line3x1 = lerp( line3.from[0], line3.to[0], t )
					this.line3y1 = lerp( line3.from[1], line3.to[1], t )
					this.line3x2 = lerp( line3.from[2], line3.to[2], t )
					this.line3y2 = lerp( line3.from[3], line3.to[3], t )
				}
			}
			else
			{
				this.$emit('burger-close');
				stepFunc = (t) =>
				{
					this.line1x1 = lerp( line1.to[0], line1.from[0], t )
					this.line1y1 = lerp( line1.to[1], line1.from[1], t )
					this.line1x2 = lerp( line1.to[2], line1.from[2], t )
					this.line1y2 = lerp( line1.to[3], line1.from[3], t )

					this.line2x1 = lerp( line2.to[0], line2.from[0], t )
					this.line2y1 = lerp( line2.to[1], line2.from[1], t )
					this.line2x2 = lerp( line2.to[2], line2.from[2], t )
					this.line2y2 = lerp( line2.to[3], line2.from[3], t )

					this.line3x1 = lerp( line3.to[0], line3.from[0], t )
					this.line3y1 = lerp( line3.to[1], line3.from[1], t )
					this.line3x2 = lerp( line3.to[2], line3.from[2], t )
					this.line3y2 = lerp( line3.to[3], line3.from[3], t )
				}
			}
			this.$raf.addListener('tick', onTick);
		}
	},

	methods:
	{
		toggle()
		{
			if (this.isTransitioning)	return;
			this.isOpen = !this.isOpen;
			if (this.isOpen)
				this.$emit('open')
			else
				this.$emit('close')
		}
	}
}
