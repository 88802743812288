//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import svgSymbol from '~/components/svg-symbol'
import RestString from '~/components/rest-string'
import RestStringLink from '~/components/rest-string-link'
import Burger from '~/components/menu-burger'

export default
{
	components:
	{
		// you *MUST* declare components before you can use them
		svgSymbol,
		RestString,
		RestStringLink,
		Burger
	},

	props:
	{
		menuItems: {
			type: Array,
			required: true,
		},

		// hasQuoteButton:
		// {
		// 	type: Boolean,
		// 	default: false
		// },

		// showLang:
		// {
		// 	type: Boolean,
		// 	default: true,
		// },

		// externLinkUrl:
		// {
		// 	type: String,
		// 	default: '#'
		// },

		// externLinkLabel:
		// {
		// 	type: String,
		// 	default: 'other site'
		// },

		isLearningSchool:
		{
			type: Boolean,
			default: true
		}


	},

	data()
	{
		return {
			showMobileMenu: false,
			scrollY: 0,
		};
	},

	computed:
	{
		...mapGetters(['getString', 'commonWords', 'menuData', 'footerData', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isLaptop', 'isLaptopOrBigger', 'isDesktop']),

		menuStyle()
		{
			return {
				opacity: this.showMobileMenu ? 1 : 0,
				'pointer-events': this.showMobileMenu ? 'all' : 'none'
			}
		},

		logo()
		{
			var list = this.menuItems.filter(x => x.type=='logo');
			if (!list || list.length==0)
				return null;
			else
				return list[0]
		},

		langListEntry()
		{
			var list = this.menuItems.filter(x => x.type=='lang-list');
			if (!list || list.length==0)
			{
				// console.erro('no lang-list found')
				return null;
			}
			else
			{
				// consola.debug('lang-list: ', list[0])
				return list[0]
			}
		},

		langList()
		{
			let list = JSON.parse( JSON.stringify(this.allLanguages) );
			list.sort( (a, b) =>
			{
				if (a.display_order == -1 || !Number.isInteger(a.display_order) )
				{
					if (b.display_order == -1 || !Number.isInteger(b.display_order) )
					{
						return 0;	// they are the same
					}
					return 1;	// a is after b
				}
				if (b.display_order == -1 || !Number.isInteger(b.display_order) )
				{
					return -1;		// a is before b
				}
				return a.display_order - b.display_order;
			} )

			return list;
		},

		externalLink()
		{
			var list = this.menuItems.filter(x => x.type=='link' && x.is_external);
			if (!list || list.length==0)
				return null;
			else
				return list[0]
		},

		formButton()
		{
			var list = this.menuItems.filter(x => x.type=='form-button');
			if (!list || list.length==0)
				return null;
			else
				return list[0]
		},

		footerUpperLabel()
		{
			return this.footerUpperList.find(x => x.type="label");
		},

		footerUpperFooterItems()
		{
			return this.footerUpperList.filter(x => x.type=='footer-item');
		},

		footerUpperList()
		{
			// debugger;
			if (!this.footerData)	return [];
			let section = this.footerData.filter(x => x.name=="upper" ) || null;

			return section && section[0] && section[0].list || [];
		},

		footerLowerList()
		{
			if (!this.footerData)	return [];
			let section = this.footerData.filter(x => x.name=="lower" ) || null;

			return section && section[0] && section[0].list || [];
		},
	},

	watch:
	{
		showMobileMenu(newVal)
		{

			if (newVal == true)
			{
				// Disable page scroll when the menu is open
				setTimeout(() =>
				{
					this.scrollY = document.body.scrollTop || document.documentElement.scrollTop;
					document.body.style = "height: 100vh !important;overflow-y: hidden";
				}, 300);
				// document.body.style.height = "100vh !important";
				// document.documentElement.style.height = "100vh !important";
				// document.body.style.overflowY = "hidden";
				// document.documentElement.style.overflowY = "hidden";
			}
			else
			{
				// Re-enable page scroll when the menu is closed
				document.body.style = "";
				// document.body.style.height = "";
				// document.documentElement.style.height = "";
				// document.body.style.overflowY = "";
				// document.documentElement.style.overflowY = "";
				// this.scrollY = document.body.scrollTop;
				document.body.scrollTop = this.scrollY;
				document.documentElement.scrollTop = this.scrollY;
			}


			this.setMobileMenuVisible(newVal);
			this.$root.$emit('showMobileMenu', newVal);
		}
	},

	mounted()
	{
		this.$root.$on('setScrolledToTop', (isScrollTop) => { this.scrollTop = isScrollTop; })
		consola.debug('menuItems: ', this.menuItems)
	},

	methods:
	{
		...mapActions(['setLang']),
		...mapMutations(['setMobileMenuVisible']),

		scrollTo(event)
		{
			this.showMobileMenu = false;
			if (this.$refs.burger)
			{
				this.$refs.burger.toggle();
			}
			this.$parent.$parent.scrollToAnchor(event);
		},

		changeLang(item)
		{
			// consola.debug('changing lang to ', item.code);
			consola.debug('changing lang to ', item.lang_code);
			// this.setLang({ lang: item.lang_code });
			var parts = window.location.pathname.split('/');
			// remove the 1st element
			parts.shift();
			this.$router.push( `/${item.lang_code}/` + parts.join('/') )
		},
		

		onLangChange(event)
		{
			const idx = parseInt(event.target.value) || 0;
			// console.log('onLangChange', event.target.value);
			window.location.href = '/' + this.langList[idx].lang_code + this.$route.path.substring(3);
		},


		onPhoneClick(event)
		{
			// getString('telephone_content')
			const phone = this.getString('telephone_content');
			if ( phone )
				window.open(`tel:${phone}`, "_blank");
		},

		// onShowQuoteForm(event)
		// {
		// 	this.$root.$emit( 'show-quote-form' )
		// },

		openForm(url, label)
		{
			this.$root.$emit('show-external-form', url)
			this.$gtag && this.$gtag.event( 'click', { event_category:label, event_label:  'open form', value:  1 });
		},

		clickAnalytics(item)
		{
			item.analytics_event && this.$gtag && this.$gtag.event( 'click', { event_category:'Link', event_label:  item.analytics_event, value:  1 });
			this.$fb && this.$fb.track('track', 'Contact');
		},

		isSocial(item)
		{
			if (!item.has_icon)	return false;

			switch (item.analytics_event)
			{
				case "Facebook":
				case "Twitter":
				case "LinkedIn":
				case "Instagram":
				case "Youtube":
					return item.analytics_event;

				default: return false;
			}

			return false;
		}

	}
}
