const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isTrident, isEdge, isSafari, isFirefox, isYandex } from '~/plugins/browser-detect'
const toPX = require('to-px')

import WheelPassthroughMixin from '~/mixins/scrollwheel-passthrough'
import ScrollSpyMixin from '~/mixins/scrollspy-data-section'
// import Dropdown from '~/components/dropdown'
import vClickOutside from 'v-click-outside'

import detectBrowserClasses from '~/plugins/browser-detect'
import { cloneDeep } from 'lodash'

export default {

	// components: {
	// 	Dropdown,
	// },

	directives: {
		clickOutside: vClickOutside.directive
	},

	mixins: [WheelPassthroughMixin, ScrollSpyMixin],

	props:
	{
		menuItems: {
			type: Array,
			required: true,
		},

		mobileMenuItems: {
			type: Array,
			default: null,
		},

		showLang:
		{
			type: Boolean,
			default: true,
		},

		hasQuoteButton:
		{
			type: Boolean,
			default: false,
		},

		isLearningSchool:
		{
			type: Boolean,
			default: true
		},

		externLinkLabel:
		{
			type: String,
		},

		externLinkUrl:
		{
			type: String,
		}
	},

	data()
	{
		return {
			showTopBanner: false,
			showLangDropDown: false,
		}
	},

	head()
	{
		// if (process.browser)
		// {
		// 	if (isTrident()) document.documentElement.classList.add('browser-trident');
		// 	if (isEdge()) document.documentElement.classList.add('browser-edge');
		// 	if (isSafari()) document.documentElement.classList.add('browser-safari');
		// }

		return {
			// htmlAttrs:
			// {
			// 	// lang: this.$store.getters.lang,
			// 	class: [
			// 		isTrident() ? 'browser-trident': '',
			// 		isEdge() ? 'browser-edge': '',
			// 		isSafari() ? 'browser-safari': '', //,
			// 	]
			// },
			bodyAttrs: {
				class: [
					this.showMobileMenu ? 'show-mobile-menu' : '',
					// this.showModal > 0 ? 'show-modal' : '',
					this.topBanner ? 'has-topbanner' : '',
					this.$device.isIos ? 'os-ios' : '',
					this.$device.isWindows ? 'os-windows' : '',
					this.$device.isMacOS && !this.$device.isIos ? 'os-macos' : '',
					!(this.$device.isIos || this.$device.isWindows || this.$device.isMacOS) ? 'os-android' : '',
					isTrident() ? 'browser-trident': '',
					isEdge() ? 'browser-edge': '',
					isSafari() ? 'browser-safari': '', //,
					isFirefox() ? 'browser-firefox': '', //,
					isYandex() ? 'browser-yandex': '', //,
				]
			}
		}
	},

	computed:
	{
		...mapGetters(['getString', 'commonWords', 'topBanner', 'mobileMenuVisible', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'isLaptopOrBigger', 'isLargerThanTabletLandscape', 'scrollTop',]),


		langList()
		{
			// debugger;
			let list = JSON.parse( JSON.stringify(this.allLanguages) );
			list.sort( (a, b) =>
			{
				if (a.display_order == -1 || !Number.isInteger(a.display_order) )
				{
					if (b.display_order == -1 || !Number.isInteger(b.display_order) )
					{
						return 0;	// they are the same
					}
					return 1;	// a is after b
				}
				if (b.display_order == -1 || !Number.isInteger(b.display_order) )
				{
					return -1;		// a is before b
				}
				return a.display_order - b.display_order;
			} )
			consola.debug(list);
			return list;
		},
	},

	watch:
	{
		// showMobileMenu()
		// {
		// 	debugger;
		// 	this.$meta().refresh();
		// }

		showLangDropDown(newVal)
		{
			consola.debug('showLangDropDown: ', newVal)
		},

		topBanner(newVal)
		{
			if (newVal)
			{
				this.showTopBanner = true;
			}
		},

		'$route': function(newVal)
		{
			detectBrowserClasses();
		}
	},

	beforeMount()
	{
		// this.$meta().refresh();
		detectBrowserClasses();
	},

	beforeDestroy()
	{
		this.$root.$off('showMobileMenu');
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		this.$root.$on('showMobileMenu', (val) => { this.showMobileMenu = val } );

		if (process.browser)
		{
			setTimeout( ()=>
			{
				var cookieBar = document.querySelector('.cookieControl__Bar');
				if (!cookieBar)
				{
					consola.debug('no cookie bar found')
					return;
				}

				cookieBar.addEventListener('wheel', this.onWheelPassthrough.bind(this));

			}, 200)

			setTimeout( () => { this.scrollSpyInit() }, 0);
		}
	},

	methods:
	{
		...mapMutations( ['setTopBanner', 'setMobileMenuVisible',] ),

		clickOutsideMenu(event)
		{
			this.showLangDropDown = false;
		},

		scrollToAnchor(event)
		{
			return this.$parent.scrollToAnchor(event);
		},

		// openFreeQuoteForm()
		// {
		// 	this.$root.$emit('show-quote-form')
		// 	this.$gtag && this.$gtag.event( 'click', { event_category:'Free Quote', event_label:  'open form', value:  1 });
		// },

		openForm(url, label)
		{
			consola.debug('openForm()')
			this.$root.$emit('show-external-form', url)
			this.$gtag && this.$gtag.event( 'click', { event_category:label, event_label:  'open form', value:  1 });
		},


	}

}
