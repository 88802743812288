//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import MenuMobile from '~/components/menu-mobile'
import svgSymbol from '~/components/svg-symbol'
import RestStringLink from '~/components/rest-string-link'

import {
	VIcon,
	VBtn,
} from 'vuetify/lib'

import menu from './menu-comp.js'

export default {

	...menu,

	components:
	{
		VIcon,
		VBtn,
		MenuMobile,
		svgSymbol,
		'rest-string-link': RestStringLink,
	},

	data()
	{
		return {
			menuName: null,
			showLangDropDown: false,
		}
	},

	mounted()
	{
		this.$eventBus.$on( 'set-page-data', (data) =>
		{
			if (!data)	return;
			this.menuName = data.menu.name;
			// consola.debug('set menu name: ', this.menuName);
		});
	},

	methods:
	{
		clickOutsideMenu(event)
		{
			this.showLangDropDown = false;
		},

		onEditMenu()
		{
			consola.debug('onEditMenu');
			// const menu = this.$store.getters.menuData;
			// debugger;
			this.$root.$emit('reveal:collection', 'Menus', { name: this.menuName })
		}
	}

}
