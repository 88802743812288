//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex'
import MenuMobile from '~/components/menu-mobile'
import svgSymbol from '~/components/svg-symbol'
import RestStringLinkDummy from '~/components/rest-string-link-dummy'

import menu from './menu-comp.js'

export default {

	...menu,

	components:
	{
		MenuMobile,
		svgSymbol,
		'rest-string-link': RestStringLinkDummy,
	},

}
